table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}
thead{
    background-color: #DDE7EE;
    color: #494F55;
    font-size: large;
    font-weight: bolder;
}
