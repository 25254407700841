.footer{
    width: 100vw;
    height: 42px;
    background: #FFF;
    box-shadow: 0px -3.011111259460449px 6.77500057220459px 0px rgba(55, 94, 173, 0.13);
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerText{
    color: var(--grey-01, #5B5B5B);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}