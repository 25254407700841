.drinking-water-line {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 80px 0px;
}

.drinking-water-line .div {
  background-color: #f9f9f9;
  border: 1px none;
  height: 2447px;
  overflow: hidden;
  position: relative;
  width: 85%;
}

.drinking-water-line .overlap {
  background-color: #ffffff;
  box-shadow: 0px 3.01px 6.78px #365ead21;
  height: 67px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.drinking-water-line .ci-hamburger-md {
  height: 34px;
  left: 30px;
  position: absolute;
  top: 16px;
  width: 34px;
}

.drinking-water-line .image {
  height: 36px;
  left: 1107px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 36px;
}

.drinking-water-line .text-wrapper {
  color: #43abe0;
  font-family: "Lato", Helvetica;
  font-size: 16.7px;
  font-weight: 600;
  left: 1156px;
  letter-spacing: 0.83px;
  line-height: normal;
  position: absolute;
  top: 22px;
  white-space: nowrap;
}

.drinking-water-line .text-wrapper-2 {
  color: var(--primary-blue);
  font-family: "Lato", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 87px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.drinking-water-line .overlap-group {
  height: 2340px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
}

.drinking-water-line .overlap-group-2 {
  height: 2340px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.drinking-water-line .line {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 2339px;
  width: 100%;
}

.drinking-water-line .rectangle {
  background-color: #f9f9f9;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 4px 0px 8px #0000000d;
  height: 75px;
  left: 219px;
  position: absolute;
  top: 0;
  width: 220px;
}

.drinking-water-line .rectangle-2 {
  background-color: #f9f9f9;
  height: 2276px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
}

.drinking-water-line .rectangle-3 {
  border-radius: 2.91px;
  height: 2182px;
  left: 47px;
  position: absolute;
  top: 111px;
  width: 1192px;
}

.drinking-water-line .text-wrapper-3 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 600;
  left: 271px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.drinking-water-line .vector {
  height: 122px;
  left: 300px;
  position: absolute;
  top: 474px;
  width: 88px;
}

.drinking-water-line .img {
  height: 1px;
  left: 580px;
  position: absolute;
  top: 568px;
  width: 64px;
}

.drinking-water-line .vector-2 {
  height: 55px;
  left: 512px;
  position: absolute;
  top: 601px;
  width: 1px;
}

.drinking-water-line .vector-3 {
  height: 1px;
  left: 629px;
  position: absolute;
  top: 688px;
  width: 37px;
}

.drinking-water-line .vector-4 {
  height: 1px;
  left: 629px;
  object-fit: cover;
  position: absolute;
  top: 730px;
  width: 37px;
}

.drinking-water-line .vector-5 {
  height: 1px;
  left: 629px;
  position: absolute;
  top: 770px;
  width: 37px;
}

.drinking-water-line .vector-6 {
  height: 543px;
  left: 130px;
  position: absolute;
  top: 620px;
  width: 146px;
}

.drinking-water-line .vector-7 {
  height: 1354px;
  left: 304px;
  position: absolute;
  top: 620px;
  width: 68px;
}

.drinking-water-line .vector-8 {
  height: 589px;
  left: 332px;
  position: absolute;
  top: 609px;
  width: 361px;
}

.drinking-water-line .vector-9 {
  height: 34px;
  left: 359px;
  position: absolute;
  top: 792px;
  width: 201px;
}

.drinking-water-line .vector-10 {
  height: 44px;
  left: 570px;
  position: absolute;
  top: 785px;
  width: 1px;
}

.drinking-water-line .vector-11 {
  height: 62px;
  left: 558px;
  position: absolute;
  top: 939px;
  width: 1px;
}
/*  */
.drinking-water-line .vector-weld_shop1 {
  height: 30px;
  left: 513px;
  position: absolute;
  top: 999px;
  width: 1px;
}
.drinking-water-line .vector-press_shop {
  height: 30px;
  left: 698px;
  position: absolute;
  top: 999px;
  width: 1px;
}
.drinking-water-line .vector-tcf_1_side {
  height: 173px;
  left: 360px;
  position: absolute;
  top: 620px;
  width: 1px;
}
/*  */

.drinking-water-line .vector-12 {
  height: 1px;
  left: 400px;
  object-fit: cover;
  position: absolute;
  top: 896px;
  width: 30px;
}

.drinking-water-line .vector-13 {
  height: 1px;
  left: 400px;
  object-fit: cover;
  position: absolute;
  top: 918px;
  width: 30px;
}

.drinking-water-line .vector-14 {
  height: 36px;
  left: 622px;
  position: absolute;
  top: 785px;
  width: 250px;
}

.drinking-water-line .vector-15 {
  height: 44px;
  left: 788px;
  position: absolute;
  top: 1102px;
  width: 121px;
}

.drinking-water-line .vector-16 {
  height: 54px;
  left: 788px;
  position: absolute;
  top: 1112px;
  width: 121px;
}

.drinking-water-line .vector-17 {
  height: 48px;
  left: 738px;
  position: absolute;
  top: 1125px;
  width: 1px;
}

.drinking-water-line .vector-18 {
  height: 198px;
  left: 872px;
  position: absolute;
  top: 1166px;
  width: 30px;
}

.drinking-water-line .vector-19 {
  height: 9px;
  left: 558px;
  position: absolute;
  top: 1185px;
  width: 1px;
}
/*  */
.drinking-water-line .vector-service_line {
  height: 26px;
  left: 396px;
  position: absolute;
  top: 1185px;
  width: 1px;
}
.drinking-water-line .vector-office_block1 {
  height: 17px;
  left: 595px;
  position: absolute;
  top: 1194px;
  width: 1px;
}
.drinking-water-line .vector-office_block2 {
  height: 17px;
  left: 522px;
  position: absolute;
  top: 1194px;
  width: 1px;
}
/*  */

.drinking-water-line .vector-20 {
  height: 112px;
  left: 558px;
  position: absolute;
  top: 1284px;
  width: 183px;
}

.drinking-water-line .vector-21 {
  height: 1px;
  left: 305px;
  object-fit: cover;
  position: absolute;
  top: 1444px;
  width: 74px;
}
/*  */
.drinking-water-line .vector-service_block_line {
  height: 1px;
  left: 466px;
  object-fit: cover;
  position: absolute;
  top: 1245px;
  width: 20px;
}
.drinking-water-line .vector-canteen {
  height: 1px;
  left: 800px;
  object-fit: cover;
  position: absolute;
  top: 1438px;
  width: 25px;
}
.drinking-water-line .vector-weld_shop {
  height: 1px;
  left: 513px;
  object-fit: cover;
  position: absolute;
  top: 999px;
  width: 187px;
}
.drinking-water-line .vector-office_block {
  height: 1px;
  left: 522px;
  object-fit: cover;
  position: absolute;
  top: 1194px;
  width: 74px;
}
/*  */

.drinking-water-line .vector-22 {
  height: 108px;
  left: 802px;
  position: absolute;
  top: 1512px;
  width: 188px;
}

.drinking-water-line .vector-23 {
  height: 36px;
  left: 896px;
  position: absolute;
  top: 1496px;
  width: 26px;
}

.drinking-water-line .vector-24 {
  height: 238px;
  left: 690px;
  position: absolute;
  top: 1532px;
  width: 218px;
}

.drinking-water-line .vector-25 {
  height: 1px;
  left: 527px;
  position: absolute;
  top: 1976px;
  width: 45px;
}

.drinking-water-line .vector-26 {
  height: 1px;
  left: 623px;
  object-fit: cover;
  position: absolute;
  top: 1976px;
  width: 27px;
}

.drinking-water-line .vector-27 {
  height: 1px;
  left: 704px;
  object-fit: cover;
  position: absolute;
  top: 1974px;
  width: 28px;
}

.drinking-water-line .vector-28 {
  height: 1px;
  left: 456px;
  object-fit: cover;
  position: absolute;
  top: 1976px;
  width: 22px;
}

.drinking-water-line .vector-29 {
  height: 40px;
  left: 602px;
  position: absolute;
  top: 2010px;
  width: 26px;
}

.drinking-water-line .vector-30 {
  height: 69px;
  left: 598px;
  position: absolute;
  top: 2010px;
  width: 32px;
}

.drinking-water-line .vector-31 {
  height: 92px;
  left: 796px;
  position: absolute;
  top: 2010px;
  width: 74px;
}

.drinking-water-line .vector-32 {
  height: 120px;
  left: 788px;
  position: absolute;
  top: 2010px;
  width: 82px;
}

.drinking-water-line .vector-33 {
  height: 154px;
  left: 780px;
  position: absolute;
  top: 2010px;
  width: 90px;
}

.drinking-water-line .vector-34 {
  height: 182px;
  left: 772px;
  position: absolute;
  top: 2010px;
  width: 98px;
}

.drinking-water-line .vector-35 {
  height: 1px;
  left: 626px;
  position: absolute;
  top: 1284px;
  width: 10px;
}

.drinking-water-line .vector-36 {
  height: 1px;
  left: 626px;
  position: absolute;
  top: 1209px;
  width: 9px;
}

.drinking-water-line .vector-37 {
  height: 72px;
  left: 625px;
  position: absolute;
  top: 1028px;
  width: 1px;
}

.drinking-water-line .text-wrapper-4 {
  color: #231f20;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 934px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 381px;
  width: 82px;
}

.drinking-water-line .text-wrapper-5 {
  color: #231f20;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 934px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1872px;
  width: 82px;
}

.drinking-water-line .text {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 214px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 501px;
  width: 1px;
}

.drinking-water-line .vector-38 {
  height: 8px;
  left: 1054px;
  position: absolute;
  top: 2219px;
  width: 4px;
}

.drinking-water-line .vector-39 {
  height: 1px;
  left: 299px;
  position: absolute;
  top: 2227px;
  width: 753px;
}

.drinking-water-line .vector-40 {
  height: 8px;
  left: 292px;
  position: absolute;
  top: 2219px;
  width: 5px;
}

.drinking-water-line .vector-41 {
  height: 356px;
  left: 292px;
  position: absolute;
  top: 1860px;
  width: 1px;
}

.drinking-water-line .vector-42 {
  height: 6px;
  left: 292px;
  position: absolute;
  top: 1852px;
  width: 5px;
}

.drinking-water-line .vector-43 {
  height: 1px;
  left: 299px;
  position: absolute;
  top: 1852px;
  width: 752px;
}

.drinking-water-line .vector-44 {
  height: 6px;
  left: 1052px;
  position: absolute;
  top: 1852px;
  width: 6px;
}

.drinking-water-line .vector-45 {
  height: 357px;
  left: 1058px;
  position: absolute;
  top: 1860px;
  width: 1px;
}

.drinking-water-line .vector-46 {
  height: 6px;
  left: 1054px;
  position: absolute;
  top: 1806px;
  width: 4px;
}

.drinking-water-line .vector-47 {
  height: 1px;
  left: 210px;
  position: absolute;
  top: 1812px;
  width: 842px;
}

.drinking-water-line .vector-48 {
  height: 6px;
  left: 200px;
  position: absolute;
  top: 1806px;
  width: 8px;
}

.drinking-water-line .vector-49 {
  height: 1463px;
  left: 200px;
  position: absolute;
  top: 341px;
  width: 1px;
}

.drinking-water-line .vector-50 {
  height: 5px;
  left: 200px;
  position: absolute;
  top: 334px;
  width: 6px;
}

.drinking-water-line .vector-51 {
  height: 1px;
  left: 208px;
  position: absolute;
  top: 334px;
  width: 838px;
}

.drinking-water-line .vector-52 {
  height: 5px;
  left: 1053px;
  position: absolute;
  top: 334px;
  width: 5px;
}

.drinking-water-line .vector-53 {
  height: 1462px;
  left: 1058px;
  position: absolute;
  top: 342px;
  width: 1px;
}

.drinking-water-line .vector-54 {
  height: 28px;
  left: 290px;
  position: absolute;
  top: 430px;
  width: 18px;
}

.drinking-water-line .vector-55 {
  height: 97px;
  left: 227px;
  position: absolute;
  top: 524px;
  width: 138px;
}

.drinking-water-line .vector-56 {
  height: 106px;
  left: 414px;
  position: absolute;
  top: 508px;
  width: 177px;
}

.drinking-water-line .vector-57 {
  height: 150px;
  left: 369px;
  position: absolute;
  top: 648px;
  width: 271px;
}

.drinking-water-line .vector-58 {
  height: 20px;
  left: 666px;
  position: absolute;
  top: 679px;
  width: 87px;
}

.drinking-water-line .vector-59 {
  height: 19px;
  left: 666px;
  position: absolute;
  top: 760px;
  width: 87px;
}

.drinking-water-line .vector-60 {
  height: 134px;
  left: 420px;
  position: absolute;
  top: 818px;
  width: 300px;
}

.drinking-water-line .vector-61 {
  height: 194px;
  left: 862px;
  position: absolute;
  top: 799px;
  width: 152px;
}

.drinking-water-line .vector-62 {
  height: 1px;
  left: 788px;
  object-fit: cover;
  position: absolute;
  top: 1064px;
  width: 125px;
}

.drinking-water-line .vector-63 {
  height: 77px;
  left: 913px;
  position: absolute;
  top: 1024px;
  width: 83px;
}

.drinking-water-line .vector-64 {
  height: 120px;
  left: 369px;
  position: absolute;
  top: 1020px;
  width: 430px;
}

.drinking-water-line .text-wrapper-6 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 450px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1065px;
  width: 98px;
}

.drinking-water-line .vector-65 {
  height: 76px;
  left: 635px;
  position: absolute;
  top: 1209px;
  width: 148px;
}

.drinking-water-line .vector-66 {
  height: 96px;
  left: 475px;
  position: absolute;
  top: 1201px;
  width: 162px;
}
/*  */
.drinking-water-line .vector-service_block {
  height: 96px;
  left: 317px;
  position: absolute;
  top: 1201px;
  width: 162px;
}
/*  */

.drinking-water-line .vector-67 {
  height: 18px;
  left: 644px;
  position: absolute;
  top: 559px;
  width: 78px;
}

.drinking-water-line .vector-68 {
  height: 17px;
  left: 337px;
  position: absolute;
  top: 909px;
  width: 61px;
}

.drinking-water-line .vector-69 {
  height: 18px;
  left: 337px;
  position: absolute;
  top: 889px;
  width: 61px;
}

.drinking-water-line .vector-70 {
  height: 13px;
  left: 909px;
  position: absolute;
  top: 1139px;
  width: 48px;
}

.drinking-water-line .vector-71 {
  height: 15px;
  left: 909px;
  position: absolute;
  top: 1158px;
  width: 48px;
}

.drinking-water-line .vector-72 {
  height: 13px;
  left: 919px;
  position: absolute;
  top: 1528px;
  width: 60px;
}

.drinking-water-line .vector-73 {
  height: 13px;
  left: 961px;
  position: absolute;
  top: 1619px;
  width: 60px;
}

.drinking-water-line .vector-74 {
  height: 14px;
  left: 918px;
  position: absolute;
  top: 1488px;
  width: 66px;
}

.drinking-water-line .vector-75 {
  height: 81px;
  left: 655px;
  position: absolute;
  top: 1666px;
  width: 62px;
}

.drinking-water-line .vector-76 {
  height: 68px;
  left: 370px;
  position: absolute;
  top: 1943px;
  width: 87px;
}

.drinking-water-line .vector-77 {
  height: 68px;
  left: 478px;
  position: absolute;
  top: 1943px;
  width: 50px;
}

.drinking-water-line .vector-78 {
  height: 68px;
  left: 572px;
  position: absolute;
  top: 1943px;
  width: 52px;
}

.drinking-water-line .vector-79 {
  height: 68px;
  left: 650px;
  position: absolute;
  top: 1943px;
  width: 55px;
}

.drinking-water-line .vector-80 {
  height: 68px;
  left: 732px;
  position: absolute;
  top: 1943px;
  width: 84px;
}

.drinking-water-line .OFFICE-BUILDING {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 657px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1967px;
  width: 42px;
}

.drinking-water-line .vector-81 {
  height: 16px;
  left: 630px;
  position: absolute;
  top: 2043px;
  width: 54px;
}

.drinking-water-line .vector-82 {
  height: 16px;
  left: 630px;
  position: absolute;
  top: 2072px;
  width: 54px;
}

.drinking-water-line .vector-83 {
  height: 13px;
  left: 870px;
  position: absolute;
  top: 2095px;
  width: 64px;
}

.drinking-water-line .vector-84 {
  height: 16px;
  left: 870px;
  position: absolute;
  top: 2121px;
  width: 64px;
}

.drinking-water-line .vector-85 {
  height: 16px;
  left: 870px;
  position: absolute;
  top: 2154px;
  width: 64px;
}

.drinking-water-line .vector-86 {
  height: 15px;
  left: 870px;
  position: absolute;
  top: 2183px;
  width: 64px;
}

.drinking-water-line .vector-87 {
  height: 40px;
  left: 740px;
  position: absolute;
  top: 1454px;
  width: 1px;
}
/*  */
.drinking-water-line .engine_shop_line {
  height: 53px;
  left: 517px;
  position: absolute;
  top: 1453px;
  width: 1px;
}
.drinking-water-line .cantine_line {
  height: 75px;
  left: 824px;
  position: absolute;
  top: 1438px;
  width: 1px;
}
/*  */

.drinking-water-line .vector-88 {
  height: 130px;
  left: 368px;
  position: absolute;
  top: 1336px;
  width: 444px;
}

.drinking-water-line .vector-89 {
  height: 37px;
  left: 629px;
  position: absolute;
  top: 1494px;
  width: 174px;
}

.drinking-water-line .vector-90 {
  height: 16px;
  left: 902px;
  position: absolute;
  top: 1357px;
  width: 71px;
}

.drinking-water-line .FROM-PUMP-HOUSE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 265px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 360px;
  width: 75px;
}

.drinking-water-line .TO-j-BLOCK-TO {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 66px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 1177px;
  width: 120px;
}

.drinking-water-line .UNDERGROUND-TANK {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 376px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1968px;
  width: 73px;
}

.drinking-water-line .PUMP-FOR-LIFT {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 489px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1963px;
  width: 28px;
}

.drinking-water-line .TERRACE-TANK {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 580px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1967px;
  width: 36px;
}

.drinking-water-line .text-wrapper-7 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 746px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1968px;
  white-space: nowrap;
  width: 55px;
}

.drinking-water-line .text-wrapper-8 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 635px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2045px;
  white-space: nowrap;
  width: 43px;
}

.drinking-water-line .text-wrapper-9 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 634px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2074px;
  white-space: nowrap;
  width: 50px;
}

.drinking-water-line .text-wrapper-10 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 878px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2096px;
  width: 47px;
}

.drinking-water-line .text-wrapper-11 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 886px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2124px;
  width: 32px;
}

.drinking-water-line .text-wrapper-12 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 875px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2157px;
  white-space: nowrap;
  width: 54px;
}

.drinking-water-line .text-wrapper-13 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 887px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2185px;
  width: 30px;
}

.drinking-water-line .text-wrapper-14 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 497px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1233px;
  width: 118px;
}
/*  */
.drinking-water-line .text-wrapper-office_block {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 342px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1233px;
  width: 118px;
}
/*  */

.drinking-water-line .text-wrapper-15 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 479px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1398px;
  width: 113px;
}

.drinking-water-line .text-wrapper-16 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 668px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1056px;
  width: 101px;
}

.drinking-water-line .text-wrapper-17 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 512px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 873px;
  white-space: nowrap;
  width: 98px;
}

.drinking-water-line .text-wrapper-18 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 923px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 883px;
  width: 29px;
}

.drinking-water-line .text-wrapper-19 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 481px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 710px;
  width: 45px;
}

.drinking-water-line .text-wrapper-20 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 481px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 558px;
  width: 45px;
}

.drinking-water-line .line-2 {
  height: 72px;
  left: 624px;
  position: absolute;
  top: 1028px;
  width: 1px;
}

.drinking-water-line .vector-91 {
  height: 14px;
  left: 700px;
  position: absolute;
  top: 1173px;
  width: 83px;
}

.drinking-water-line .PUMP-HOUSE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 259px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 551px;
  width: 74px;
}

.drinking-water-line .text-wrapper-21 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 684px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 683px;
  white-space: nowrap;
  width: 53px;
}

.drinking-water-line .vector-92 {
  height: 48px;
  left: 666px;
  position: absolute;
  top: 706px;
  width: 87px;
}

.drinking-water-line .text-wrapper-22 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 724px;
  width: 37px;
}

.drinking-water-line .text-wrapper-23 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 665px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 561px;
  width: 44px;
}

.drinking-water-line .text-wrapper-24 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 678px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 763px;
  white-space: nowrap;
  width: 62px;
}

.drinking-water-line .text-wrapper-25 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 345px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 911px;
  white-space: nowrap;
  width: 44px;
}

.drinking-water-line .text-wrapper-26 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 344px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 892px;
  white-space: nowrap;
  width: 46px;
}

.drinking-water-line .SUPPORT-SERVICE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 923px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1044px;
  width: 48px;
}

.drinking-water-line .text-wrapper-27 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 918px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1140px;
  width: 30px;
}

.drinking-water-line .text-wrapper-28 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 918px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1160px;
  white-space: nowrap;
  width: 29px;
}

.drinking-water-line .text-wrapper-29 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 708px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1175px;
  width: 80px;
}

.drinking-water-line .text-wrapper-30 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 663px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1239px;
  white-space: nowrap;
  width: 91px;
}

.drinking-water-line .text-wrapper-31 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 909px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1359px;
  white-space: nowrap;
  width: 63px;
}

.drinking-water-line .text-wrapper-32 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 693px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1505px;
  width: 46px;
}

.drinking-water-line .text-wrapper-33 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 931px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1489px;
  white-space: nowrap;
  width: 51px;
}

.drinking-water-line .text-wrapper-34 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 928px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1529px;
  width: 68px;
}

.drinking-water-line .text-wrapper-35 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 971px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1620px;
  width: 39px;
}

.drinking-water-line .text-wrapper-36 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 662px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1700px;
  width: 65px;
}

.drinking-water-line .line-3 {
  height: 38px;
  left: 300px;
  object-fit: cover;
  position: absolute;
  top: 393px;
  width: 1px;
}

.drinking-water-line .polygon {
  height: 5px;
  left: 271px;
  position: absolute;
  top: 704px;
  width: 7px;
}

.drinking-water-line .polygon-2 {
  height: 5px;
  left: 301px;
  position: absolute;
  top: 704px;
  width: 7px;
}

.drinking-water-line .polygon-3 {
  height: 5px;
  left: 329px;
  position: absolute;
  top: 704px;
  width: 7px;
}
/*  */
.drinking-water-line .polygon-tcf_1_sideline {
  height: 5px;
  left: 357px;
  position: absolute;
  top: 704px;
  width: 7px;
}
/*  */

.drinking-water-line .polygon-4 {
  height: 6px;
  left: 556px;
  position: absolute;
  top: 812px;
  width: 7px;
}

.drinking-water-line .polygon-5 {
  height: 6px;
  left: 329px;
  position: absolute;
  top: 812px;
  width: 7px;
}

.drinking-water-line .polygon-6 {
  height: 5px;
  left: 556px;
  position: absolute;
  top: 982px;
  width: 7px;
}

.drinking-water-line .polygon-7 {
  height: 4px;
  left: 736px;
  position: absolute;
  top: 1148px;
  width: 7px;
}

.drinking-water-line .polygon-8 {
  height: 4px;
  left: 689px;
  position: absolute;
  top: 1198px;
  width: 7px;
}

.drinking-water-line .polygon-9 {
  height: 4px;
  left: 555px;
  position: absolute;
  top: 1200px;
  width: 7px;
}
/*  */
.drinking-water-line .polygon-service_line {
  height: 4px;
  left: 393px;
  position: absolute;
  top: 1193px;
  width: 7px;
}
.drinking-water-line .polygon-engine_shop {
  height: 5px;
  left: 514px;
  position: absolute;
  top: 1471px;
  width: 7px;
}
/*  */

.drinking-water-line .polygon-10 {
  height: 5px;
  left: 737px;
  position: absolute;
  top: 1471px;
  width: 7px;
}

.drinking-water-line .polygon-11 {
  height: 5px;
  left: 904px;
  position: absolute;
  top: 1633px;
  width: 7px;
}

.drinking-water-line .polygon-12 {
  height: 7px;
  left: 825px;
  position: absolute;
  top: 1767px;
  width: 5px;
}

.drinking-water-line .polygon-13 {
  height: 9px;
  left: 332px;
  position: absolute;
  top: 1969px;
  width: 4px;
}

.drinking-water-line .polygon-14 {
  height: 9px;
  left: 464px;
  position: absolute;
  top: 1972px;
  width: 5px;
}

.drinking-water-line .polygon-15 {
  height: 9px;
  left: 546px;
  position: absolute;
  top: 1972px;
  width: 5px;
}

.drinking-water-line .polygon-16 {
  height: 9px;
  left: 632px;
  position: absolute;
  top: 1972px;
  width: 5px;
}

.drinking-water-line .polygon-17 {
  height: 9px;
  left: 611px;
  position: absolute;
  top: 2045px;
  width: 5px;
}

.drinking-water-line .polygon-18 {
  height: 8px;
  left: 612px;
  position: absolute;
  top: 2075px;
  width: 5px;
}

.drinking-water-line .polygon-19 {
  height: 7px;
  left: 832px;
  position: absolute;
  top: 2099px;
  width: 4px;
}

.drinking-water-line .polygon-20 {
  height: 7px;
  left: 832px;
  position: absolute;
  top: 2127px;
  width: 4px;
}

.drinking-water-line .polygon-21 {
  height: 7px;
  left: 832px;
  position: absolute;
  top: 2160px;
  width: 4px;
}

.drinking-water-line .polygon-22 {
  height: 7px;
  left: 832px;
  position: absolute;
  top: 2189px;
  width: 4px;
}

.drinking-water-line .polygon-23 {
  height: 9px;
  left: 717px;
  position: absolute;
  top: 1971px;
  width: 5px;
}

.drinking-water-line .polygon-24 {
  height: 8px;
  left: 349px;
  position: absolute;
  top: 1994px;
  width: 4px;
}

.drinking-water-line .polygon-25 {
  height: 5px;
  left: 333px;
  position: absolute;
  top: 2050px;
  width: 8px;
}

.drinking-water-line .polygon-26 {
  height: 5px;
  left: 687px;
  position: absolute;
  top: 1755px;
  width: 8px;
}

.drinking-water-line .polygon-27 {
  height: 4px;
  left: 330px;
  position: absolute;
  top: 1148px;
  width: 7px;
}

.drinking-water-line .polygon-28 {
  height: 5px;
  left: 330px;
  position: absolute;
  top: 982px;
  width: 7px;
}

.drinking-water-line .polygon-29 {
  height: 6px;
  left: 297px;
  position: absolute;
  top: 495px;
  width: 7px;
}

.drinking-water-line .polygon-30 {
  height: 7px;
  left: 614px;
  position: absolute;
  top: 565px;
  width: 4px;
}

.drinking-water-line .polygon-31 {
  height: 7px;
  left: 643px;
  position: absolute;
  top: 685px;
  width: 6px;
}

.drinking-water-line .polygon-32 {
  height: 7px;
  left: 643px;
  position: absolute;
  top: 727px;
  width: 6px;
}

.drinking-water-line .polygon-33 {
  height: 7px;
  left: 643px;
  position: absolute;
  top: 767px;
  width: 6px;
}

.drinking-water-line .polygon-34 {
  height: 7px;
  left: 878px;
  position: absolute;
  top: 1142px;
  width: 4px;
}

.drinking-water-line .polygon-35 {
  height: 5px;
  left: 878px;
  position: absolute;
  top: 1163px;
  width: 4px;
}

.drinking-water-line .polygon-36 {
  height: 7px;
  left: 409px;
  position: absolute;
  top: 1192px;
  width: 5px;
}

.drinking-water-line .polygon-37 {
  height: 7px;
  left: 348px;
  position: absolute;
  top: 1441px;
  width: 4px;
}

.drinking-water-line .polygon-38 {
  height: 7px;
  left: 843px;
  position: absolute;
  top: 1509px;
  width: 5px;
}

.drinking-water-line .polygon-39 {
  height: 8px;
  left: 165px;
  position: absolute;
  top: 1159px;
  width: 6px;
}

.drinking-water-line .polygon-40 {
  height: 5px;
  left: 508px;
  position: absolute;
  top: 642px;
  width: 9px;
}

.drinking-water-line .polygon-41 {
  height: 6px;
  left: 567px;
  position: absolute;
  top: 811px;
  width: 7px;
}

.drinking-water-line .vector-93 {
  height: 89px;
  left: 316px;
  position: absolute;
  top: 2092px;
  width: 43px;
}

.drinking-water-line .TANK-EACH {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 319px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2096px;
  width: 34px;
}

.drinking-water-line .line-4 {
  height: 95px;
  left: 336px;
  object-fit: cover;
  position: absolute;
  top: 1997px;
  width: 1px;
}

.drinking-water-line .line-5 {
  height: 1px;
  left: 336px;
  object-fit: cover;
  position: absolute;
  top: 1997px;
  width: 34px;
}

.drinking-water-line .m1-group {
  height: 15px;
  left: 301px;
  position: absolute;
  top: 439px;
  width: 15px;
}

.drinking-water-line .m9-group {
  height: 15px;
  left: 268px;
  position: absolute;
  top: 630px;
  width: 15px;
}

.drinking-water-line .m8-group {
  height: 15px;
  left: 298px;
  position: absolute;
  top: 630px;
  width: 15px;
}

.drinking-water-line .m7-group {
  height: 15px;
  left: 326px;
  position: absolute;
  top: 630px;
  width: 15px;
}
.drinking-water-line .m6-group {
  height: 15px;
  left: 353px;
  position: absolute;
  top: 630px;
  width: 15px;
}

.drinking-water-line .m12-group {
  height: 15px;
  left: 504px;
  position: absolute;
  top: 617px;
  width: 15px;
}

.drinking-water-line .group-3 {
  height: 15px;
  left: 887px;
  position: absolute;
  top: 1138px;
  width: 15px;
}

.drinking-water-line .m27-group {
  height: 15px;
  left: 887px;
  position: absolute;
  top: 1159px;
  width: 15px;
}

.drinking-water-line .m33-group {
  height: 15px;
  left: 552px;
  position: absolute;
  top: 1287px;
  width: 15px;
}

.drinking-water-line .m20-group {
  height: 15px;
  left: 405px;
  position: absolute;
  top: 890px;
  width: 15px;
}

.drinking-water-line .m21-group {
    height: 15px;
  left: 405px;
  position: absolute;
  top: 910px;
  width: 15px;
}
.drinking-water-line .m24-group {
  height: 15px;
    left: 505px;
    position: absolute;
    top: 1006px;
    width: 15px;
}
.drinking-water-line .m25-group {
  height: 15px;
  left: 690px;
  position: absolute;
  top: 1006px;
  width: 15px;
}
.drinking-water-line .m38-group {
  height: 15px;
  left: 733px;
  position: absolute;
  top: 1475px;
  width: 15px;
}
.drinking-water-line .m39-group {
  height: 15px;
  left: 510px;
  position: absolute;
  top: 1480px;
  width: 15px;
}
.drinking-water-line .m35-group {
  height: 15px;
  left: 805px;
  position: absolute;
  top: 1431px;
  width: 15px ;
}
.drinking-water-line .m36-group {
  height: 15px;
  left: 899px;
  position: absolute;
  top: 1489px;
  width: 15px;
}
.drinking-water-line .m37-group {
  height: 15px;
  left: 899px;
  position: absolute;
  top: 1547px;
  width: 15px;
}
.drinking-water-line .m16-group {
  height: 15px;
  left: 563px;
  position: absolute;
  top: 789px;
  width: 15px;
}

.drinking-water-line .m46-group {
  height: 15px;
  left: 808px;
  position: absolute;
  top: 2121px;
  width: 15px;
}

.drinking-water-line .group-6 {
  height: 15px;
  left: 982px;
  position: absolute;
  top: 1577px;
  width: 15px;
}

.drinking-water-line .group-7 {
  height: 15px;
  left: 330px;
  position: absolute;
  top: 2069px;
  width: 15px;
}

.drinking-water-line .group-8 {
  height: 15px;
  left: 284px;
  position: absolute;
  top: 439px;
  width: 15px;
}
/*  */

.drinking-water-line .group-M30 {
  height: 15px;
  left: 517px;
  position: absolute;
  top: 1195px;
  width: 15px;
}
.drinking-water-line .group-M32 {
  height: 15px;
  left: 588px;
  position: absolute;
  top: 1195px;
  width: 15px;
}
/*  */

.drinking-water-line .vector-94 {
  height: 25px;
  left: 326px;
  position: absolute;
  top: 2118px;
  width: 25px;
}

.drinking-water-line .vector-95 {
  height: 25px;
  left: 326px;
  position: absolute;
  top: 2150px;
  width: 25px;
}

.drinking-water-line .rectangle-4 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #365ead;
  border-radius: 2.91px;
  height: 57px;
  left: 83px;
  position: absolute;
  top: 146px;
  width: 357px;
}

.drinking-water-line .rectangle-5 {
  background-color: #f7f7f7;
  border: 2px solid;
  border-color: #1bbe1b;
  border-radius: 2.91px;
  height: 57px;
  left: 466px;
  position: absolute;
  top: 146px;
  width: 356px;
}

.drinking-water-line .rectangle-6 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #365ead;
  border-radius: 2.91px;
  height: 57px;
  left: 848px;
  position: absolute;
  top: 146px;
  width: 357px;
}

.drinking-water-line .text-wrapper-37 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 190px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 164px;
  white-space: nowrap;
}

.drinking-water-line .text-wrapper-38 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 941px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 164px;
  white-space: nowrap;
}

.drinking-water-line .text-wrapper-39 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: bold;
  left: 569px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 164px;
  white-space: nowrap;
}

.drinking-water-line .vector-96 {
  height: 62px;
  left: 866px;
  position: absolute;
  top: 498px;
  width: 89px;
}

.drinking-water-line .vector-97 {
  height: 60px;
  left: 866px;
  position: absolute;
  top: 570px;
  width: 89px;
}

.drinking-water-line .text-wrapper-40 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 881px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 521px;
  white-space: nowrap;
}

.drinking-water-line .PUMP-HOUSE-m {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 888px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 581px;
}

.drinking-water-line .vector-98 {
  height: 22px;
  left: 450px;
  position: absolute;
  top: 959px;
  width: 61px;
}

.drinking-water-line .COMPRESSOR-ROOM-n {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 457px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 962px;
}

.drinking-water-line .vector-99 {
  height: 14px;
  left: 413px;
  position: absolute;
  top: 1151px;
  width: 21px;
}

.drinking-water-line .vector-100 {
  height: 14px;
  left: 441px;
  position: absolute;
  top: 1151px;
  width: 21px;
}

.drinking-water-line .text-wrapper-41 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 417px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1153px;
  white-space: nowrap;
}

.drinking-water-line .text-wrapper-42 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 445px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1153px;
  white-space: nowrap;
}

.drinking-water-line .vector-101 {
  height: 14px;
  left: 402px;
  position: absolute;
  top: 1323px;
  width: 71px;
}

.drinking-water-line .vector-102 {
  height: 14px;
  left: 549px;
  position: absolute;
  top: 1323px;
  width: 71px;
}

.drinking-water-line .text-wrapper-43 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 405px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1325px;
  white-space: nowrap;
}

.drinking-water-line .text-wrapper-44 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 558px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1325px;
  white-space: nowrap;
}

.drinking-water-line .vector-103 {
  height: 67px;
  left: 425px;
  position: absolute;
  top: 1506px;
  width: 186px;
}

.drinking-water-line .vector-104 {
  height: 16px;
  left: 538px;
  position: absolute;
  top: 1470px;
  width: 65px;
}

.drinking-water-line .vector-105 {
  height: 67px;
  left: 610px;
  position: absolute;
  top: 1572px;
  width: 186px;
}

.drinking-water-line .text-wrapper-45 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 544px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1473px;
  white-space: nowrap;
}

.drinking-water-line .p {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 449px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1534px;
  white-space: nowrap;
}

.drinking-water-line .text-wrapper-46 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 634px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1598px;
  white-space: nowrap;
}

.drinking-water-line .vector-106 {
  height: 81px;
  left: 803px;
  position: absolute;
  top: 1666px;
  width: 62px;
}

.drinking-water-line .SOLAR-CAR-PARKING {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 812px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1690px;
}

.drinking-water-line .vector-107 {
  height: 55px;
  left: 902px;
  position: absolute;
  top: 1956px;
  width: 91px;
}

.drinking-water-line .vector-108 {
  height: 16px;
  left: 281px;
  position: absolute;
  top: 459px;
  width: 39px;
}

.drinking-water-line .element-LITRE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 915px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1972px;
}

.drinking-water-line .element-LITRE-2 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 960px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1972px;
}

.drinking-water-line .line-6 {
  height: 54px;
  left: 946px;
  position: absolute;
  top: 1956px;
  width: 1px;
}

.drinking-water-line .line-7 {
  height: 15px;
  left: 300px;
  object-fit: cover;
  position: absolute;
  top: 459px;
  width: 1px;
}

.drinking-water-line .text-wrapper-47 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 306px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 462px;
  width: 9px;
}

.drinking-water-line .text-wrapper-48 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 287px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 462px;
  width: 8px;
}

.drinking-water-line .text-wrapper-49 {
  color: #5a5a5a;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 493px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.drinking-water-line .text-wrapper-50 {
  color: #5a5a5a;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.drinking-water-line .text-wrapper-51 {
  color: var(--grey-01);
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 507px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2417px;
  width: 266px;
}

.drinking-water-line .vector-109 {
  height: 100px;
  left: -23784px;
  position: absolute;
  top: -9331px;
  width: 100px;
}
