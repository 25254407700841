.paint-shop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 50px;
}

.paint-shop .div {
  background-color: #ffffff;
  border: 1px none;
  height: 1320px;
  overflow: hidden;
  position: relative;
  width: 100%;;
}


.paint-shop .text-wrapper {
  color: var(--primary-blue);
  font-family: "Lato", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 87px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.paint-shop .netpositve-logo {
  height: 38px;
  left: 1109px;
  position: absolute;
  top: 14px;
  width: 128px;
}

.paint-shop .ci-hamburger-md {
  height: 34px;
  left: 30px;
  position: absolute;
  top: 16px;
  width: 34px;
}

.paint-shop .overlap-group {
  height: 1252px;
  left: 0;
  position: absolute;
  top: -100px;
  width: 100%;;
}

.paint-shop .overlap-2 {
  height: 1252px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;;
}

.paint-shop .line {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1210px;
  width: 100%;;
}

.paint-shop .rectangle {
  background-color: #ffffff;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 4px 0px 8px #0000000d;
  height: 75px;
  left: 219px;
  position: absolute;
  top: 0;
  width: 220px;
}

.paint-shop .rectangle-2 {
  background-color: #ffffff;
  height: 1188px;
  left: 0;
  position: absolute;
  top: 64px;
  width: 100%;;
}

.paint-shop .rectangle-3 {
  background-color: #f8f8f8;
  border-radius: 2.91px;
  height: 1026px;
  left: 0px;
  position: absolute;
  top: 120px;
  width: 100%;;
}

.paint-shop .text-wrapper-2 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 600;
  left: 271px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.paint-shop .vector {
  height: 34px;
  left: 587px;
  position: absolute;
  top: 643px;
  width: 98px;
}

.paint-shop .img {
  height: 44px;
  left: 241px;
  position: absolute;
  top: 985px;
  width: 159px;
}

.paint-shop .vector-2 {
  height: 72px;
  left: 706px;
  position: absolute;
  top: 985px;
  width: 211px;
}

.paint-shop .vector-3 {
  height: 34px;
  left: 735px;
  position: absolute;
  top: 643px;
  width: 121px;
}

.paint-shop .vector-4 {
  height: 34px;
  left: 718px;
  position: absolute;
  top: 784px;
  width: 154px;
}

.paint-shop .vector-5 {
  height: 44px;
  left: 1029px;
  position: absolute;
  top: 567px;
  width: 39px;
}

.paint-shop .vector-6 {
  height: 43px;
  left: 1029px;
  position: absolute;
  top: 676px;
  width: 39px;
}

.paint-shop .vector-7 {
  height: 43px;
  left: 1029px;
  position: absolute;
  top: 624px;
  width: 39px;
}

.paint-shop .vector-8 {
  height: 43px;
  left: 1029px;
  position: absolute;
  top: 733px;
  width: 39px;
}

.paint-shop .vector-9 {
  height: 43px;
  left: 1029px;
  position: absolute;
  top: 784px;
  width: 39px;
}

.paint-shop .line-2 {
  height: 1px;
  left: 180px;
  position: absolute;
  top: 314px;
  width: 840px;
}

.paint-shop .line-3 {
  height: 1px;
  left: 180px;
  position: absolute;
  top: 554px;
  width: 204px;
}

.paint-shop .line-4 {
  height: 1px;
  left: 934px;
  position: absolute;
  top: 642px;
  width: 95px;
}

.paint-shop .line-5 {
  height: 2px;
  left: 934px;
  position: absolute;
  top: 696px;
  width: 95px;
}

.paint-shop .line-6 {
  height: 1px;
  left: 934px;
  position: absolute;
  top: 754px;
  width: 96px;
}

.paint-shop .line-7 {
  height: 1px;
  left: 934px;
  position: absolute;
  top: 808px;
  width: 95px;
}

.paint-shop .line-8 {
  height: 1px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 888px;
  width: 883px;
}

.paint-shop .line-9 {
  height: 91px;
  left: 318px;
  object-fit: cover;
  position: absolute;
  top: 889px;
  width: 1px;
}

.paint-shop .line-10 {
  height: 92px;
  left: 547px;
  object-fit: cover;
  position: absolute;
  top: 889px;
  width: 1px;
}

.paint-shop .line-11 {
  height: 107px;
  left: 794px;
  object-fit: cover;
  position: absolute;
  top: 673px;
  width: 1px;
}

.paint-shop .line-12 {
  height: 55px;
  left: 795px;
  object-fit: cover;
  position: absolute;
  top: 585px;
  width: 1px;
}

.paint-shop .line-13 {
  height: 55px;
  left: 636px;
  object-fit: cover;
  position: absolute;
  top: 585px;
  width: 1px;
}

.paint-shop .line-14 {
  height: 8px;
  left: 1019px;
  position: absolute;
  top: 310px;
  width: 1px;
}

.paint-shop .line-15 {
  height: 9px;
  left: 384px;
  position: absolute;
  top: 550px;
  width: 1px;
}

.paint-shop .line-16 {
  height: 9px;
  left: 1062px;
  position: absolute;
  top: 885px;
  width: 1px;
}

.paint-shop .line-17 {
  height: 1px;
  left: 175px;
  position: absolute;
  top: 1128px;
  width: 9px;
}

.paint-shop .line-18 {
  height: 105px;
  left: 741px;
  object-fit: cover;
  position: absolute;
  top: 889px;
  width: 1px;
}

.paint-shop .line-19 {
  height: 105px;
  left: 788px;
  object-fit: cover;
  position: absolute;
  top: 889px;
  width: 1px;
}

.paint-shop .line-20 {
  height: 108px;
  left: 835px;
  object-fit: cover;
  position: absolute;
  top: 889px;
  width: 1px;
}

.paint-shop .line-21 {
  height: 105px;
  left: 882px;
  object-fit: cover;
  position: absolute;
  top: 889px;
  width: 1px;
}

.paint-shop .line-22 {
  height: 304px;
  left: 934px;
  object-fit: cover;
  position: absolute;
  top: 585px;
  width: 1px;
}

.paint-shop .line-23 {
  height: 1px;
  left: 636px;
  position: absolute;
  top: 584px;
  width: 392px;
}

.paint-shop .line-24 {
  height: 932px;
  left: 179px;
  object-fit: cover;
  position: absolute;
  top: 197px;
  width: 1px;
}

.paint-shop .vector-10 {
  height: 43px;
  left: 385px;
  position: absolute;
  top: 364px;
  width: 51px;
}

.paint-shop .line-25 {
  height: 48px;
  left: 409px;
  object-fit: cover;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .vector-11 {
  height: 43px;
  left: 585px;
  position: absolute;
  top: 365px;
  width: 51px;
}

.paint-shop .line-26 {
  height: 49px;
  left: 609px;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .vector-12 {
  height: 43px;
  left: 652px;
  position: absolute;
  top: 365px;
  width: 51px;
}

.paint-shop .line-27 {
  height: 49px;
  left: 676px;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .vector-13 {
  height: 43px;
  left: 719px;
  position: absolute;
  top: 365px;
  width: 51px;
}

.paint-shop .line-28 {
  height: 49px;
  left: 743px;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .vector-14 {
  height: 43px;
  left: 785px;
  position: absolute;
  top: 365px;
  width: 51px;
}

.paint-shop .line-29 {
  height: 49px;
  left: 809px;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .vector-15 {
  height: 43px;
  left: 852px;
  position: absolute;
  top: 365px;
  width: 51px;
}

.paint-shop .line-30 {
  height: 49px;
  left: 876px;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .vector-16 {
  height: 43px;
  left: 919px;
  position: absolute;
  top: 365px;
  width: 51px;
}

.paint-shop .line-31 {
  height: 49px;
  left: 943px;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .line-32 {
  height: 49px;
  left: 476px;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .vector-17 {
  height: 43px;
  left: 452px;
  position: absolute;
  top: 364px;
  width: 50px;
}

.paint-shop .line-33 {
  height: 49px;
  left: 542px;
  position: absolute;
  top: 314px;
  width: 1px;
}

.paint-shop .vector-18 {
  height: 43px;
  left: 518px;
  position: absolute;
  top: 364px;
  width: 51px;
}

.paint-shop .text-wrapper-3 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 395px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-4 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 658px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-5 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 462px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-6 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 725px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-7 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 528px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-8 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 791px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-9 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 595px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-10 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 858px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-11 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 925px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 373px;
  white-space: nowrap;
}

.paint-shop .vector-19 {
  height: 29px;
  left: 726px;
  position: absolute;
  top: 998px;
  width: 32px;
}

.paint-shop .vector-20 {
  height: 29px;
  left: 773px;
  position: absolute;
  top: 998px;
  width: 32px;
}

.paint-shop .vector-21 {
  height: 29px;
  left: 820px;
  position: absolute;
  top: 998px;
  width: 32px;
}

.paint-shop .vector-22 {
  height: 29px;
  left: 867px;
  position: absolute;
  top: 998px;
  width: 32px;
}

.paint-shop .text-wrapper-12 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 595px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 647px;
}

.paint-shop .text-wrapper-13 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 745px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 647px;
}

.paint-shop .text-wrapper-14 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 759px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 792px;
}

.paint-shop .text-wrapper-15 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1035px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 575px;
}

.paint-shop .text-wrapper-16 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1035px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 632px;
}

.paint-shop .text-wrapper-17 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1035px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 686px;
}

.paint-shop .text-wrapper-18 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1035px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 744px;
}

.paint-shop .text-wrapper-19 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1035px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 797px;
}

.paint-shop .text-wrapper-20 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 994px;
}

.paint-shop .text-wrapper-21 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 746px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1030px;
}

.paint-shop .text-wrapper-22 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 730px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1001px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-23 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 777px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1001px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-24 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 824px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1001px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-25 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 871px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1001px;
  white-space: nowrap;
}

.paint-shop .vector-23 {
  height: 49px;
  left: 469px;
  position: absolute;
  top: 985px;
  width: 159px;
}

.paint-shop .STORE-CHILLER-TOWER {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 495px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 989px;
}

.paint-shop .group {
  height: 14px;
  left: 173px;
  position: absolute;
  top: 264px;
  width: 15px;
}

.paint-shop .group-2 {
  height: 14px;
  left: 283px;
  position: absolute;
  top: 309px;
  width: 15px;
}

.paint-shop .group-3 {
  height: 14px;
  left: 788px;
  position: absolute;
  top: 723px;
  width: 15px;
}

.paint-shop .group-4 {
  height: 14px;
  left: 406px;
  position: absolute;
  top: 887px;
  width: 15px;
}

.paint-shop .group-5 {
  height: 14px;
  left: 641px;
  position: absolute;
  top: 887px;
  width: 15px;
}

.paint-shop .group-6 {
  height: 14px;
  left: 983px;
  position: absolute;
  top: 581px;
  width: 15px;
}

.paint-shop .group-7 {
  height: 14px;
  left: 983px;
  position: absolute;
  top: 639px;
  width: 15px;
}

.paint-shop .group-8 {
  height: 14px;
  left: 983px;
  position: absolute;
  top: 693px;
  width: 15px;
}

.paint-shop .group-9 {
  height: 14px;
  left: 983px;
  position: absolute;
  top: 751px;
  width: 15px;
}

.paint-shop .group-10 {
  height: 14px;
  left: 983px;
  position: absolute;
  top: 806px;
  width: 15px;
}

.paint-shop .polygon {
  height: 5px;
  left: 175px;
  position: absolute;
  top: 218px;
  width: 10px;
}

.paint-shop .polygon-2 {
  height: 5px;
  left: 406px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .polygon-3 {
  height: 5px;
  left: 315px;
  position: absolute;
  top: 955px;
  width: 9px;
}

.paint-shop .polygon-4 {
  height: 5px;
  left: 544px;
  position: absolute;
  top: 955px;
  width: 9px;
}

.paint-shop .polygon-5 {
  height: 5px;
  left: 738px;
  position: absolute;
  top: 955px;
  width: 9px;
}

.paint-shop .polygon-6 {
  height: 5px;
  left: 785px;
  position: absolute;
  top: 955px;
  width: 9px;
}

.paint-shop .polygon-7 {
  height: 5px;
  left: 832px;
  position: absolute;
  top: 955px;
  width: 9px;
}

.paint-shop .polygon-8 {
  height: 5px;
  left: 879px;
  position: absolute;
  top: 955px;
  width: 9px;
}

.paint-shop .polygon-9 {
  height: 9px;
  left: 263px;
  position: absolute;
  top: 551px;
  width: 5px;
}

.paint-shop .polygon-10 {
  height: 9px;
  left: 228px;
  position: absolute;
  top: 310px;
  width: 5px;
}

.paint-shop .polygon-11 {
  height: 9px;
  left: 236px;
  position: absolute;
  top: 885px;
  width: 5px;
}

.paint-shop .polygon-12 {
  height: 9px;
  left: 954px;
  position: absolute;
  top: 581px;
  width: 5px;
}

.paint-shop .polygon-13 {
  height: 9px;
  left: 954px;
  position: absolute;
  top: 638px;
  width: 5px;
}

.paint-shop .polygon-14 {
  height: 9px;
  left: 954px;
  position: absolute;
  top: 692px;
  width: 5px;
}

.paint-shop .polygon-15 {
  height: 9px;
  left: 954px;
  position: absolute;
  top: 750px;
  width: 5px;
}

.paint-shop .polygon-16 {
  height: 9px;
  left: 954px;
  position: absolute;
  top: 804px;
  width: 5px;
}

.paint-shop .polygon-17 {
  height: 5px;
  left: 473px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .polygon-18 {
  height: 5px;
  left: 539px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .polygon-19 {
  height: 5px;
  left: 606px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .polygon-20 {
  height: 5px;
  left: 633px;
  position: absolute;
  top: 611px;
  width: 9px;
}

.paint-shop .polygon-21 {
  height: 5px;
  left: 792px;
  position: absolute;
  top: 611px;
  width: 9px;
}

.paint-shop .polygon-22 {
  height: 5px;
  left: 791px;
  position: absolute;
  top: 757px;
  width: 9px;
}

.paint-shop .polygon-23 {
  height: 5px;
  left: 673px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .polygon-24 {
  height: 5px;
  left: 740px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .polygon-25 {
  height: 5px;
  left: 806px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .polygon-26 {
  height: 5px;
  left: 873px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .polygon-27 {
  height: 5px;
  left: 940px;
  position: absolute;
  top: 336px;
  width: 9px;
}

.paint-shop .text-wrapper-26 {
  color: var(--primary-blue);
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 600;
  left: 156px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 95px;
  white-space: nowrap;
}

.paint-shop .uil-arrow-up {
  height: 24px;
  left: 104px;
  position: absolute;
  top: 94px;
  width: 24px;
}

.paint-shop .p {
  color: var(--grey-01);
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 474px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1219px;
  width: 266px;
}

.paint-shop .line-34 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1209px;
  width: 100%;;
}

.paint-shop .rectangle-4 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #5b5b5b5e;
  border-radius: 2.91px;
  height: 46px;
  left: 339px;
  position: absolute;
  top: 83px;
  width: 246px;
}

.paint-shop .text-wrapper-27 {
  color: #1e1e1e87;
  font-family: "Lato", Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 355px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 97px;
}

.paint-shop .text-wrapper-28 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 600;
  left: 472px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 97px;
}

.paint-shop .rectangle-5 {
  height: 2px;
  left: 462px;
  position: absolute;
  top: 127px;
  width: 123px;
}

.paint-shop .text-wrapper-29 {
  color: #5a5a5a;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 493px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.paint-shop .text-wrapper-30 {
  color: #5a5a5a;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.paint-shop .vector-24 {
  height: 100px;
  left: -6558px;
  position: absolute;
  top: 253px;
  width: 100px;
}

.paint-shop .group-wrapper {
  height: 46px;
  left: 1003px;
  position: fixed;
  top: 526px;
  width: 229px;
}

.paint-shop .div-wrapper {
  box-shadow: 0px 4px 10px #00000033;
  height: 46px;
}

.paint-shop .overlap-group-wrapper {
  height: 46px;
  width: 231px;
}

.paint-shop .overlap-group-2 {
  background-color: #365ead;
  border-radius: 2.91px;
  box-shadow: 0px 3.01px 6.78px #365ead21;
  height: 46px;
  position: relative;
  width: 229px;
}

.paint-shop .text-wrapper-31 {
  color: #ffffff;
  font-family: "Lato", Helvetica;
  font-size: 13px;
  font-weight: 800;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 15px;
  width: 126px;
}

.paint-shop .overlap-3 {
  height: 46px;
  left: 182px;
  position: absolute;
  top: 0;
  width: 46px;
}

.paint-shop .line-35 {
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.paint-shop .rectangle-6 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #365ead;
  border-radius: 0px 2.91px 2.91px 0px;
  height: 46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 46px;
}

.paint-shop .group-11 {
  height: 18px;
  left: 14px;
  position: absolute;
  top: 16px;
  width: 18px;
}
