.custom-navbar {

  width: 100vw;
  height: 66.66px;
  background: #FFF;
  box-shadow: 0px 3.011111259460449px 6.77500057220459px 0px rgba(55, 94, 173, 0.13);
}

.navText{
  padding-left: 23.33px;
  color: var(--primary-blue, #375EAD);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.navText2{
  color: #43ABE0;
font-size: 16.667px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.833px;
padding-left: 13px;
}