.nav.nav-tabs{
    background: #F9F9F9;
    border: none;
}


.NavLink{
    width: 220px;
height: 64px;

}