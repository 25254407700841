#water-tabs{
    border-radius: 2.91px;
    background: #FFF;
    border: 1px solid rgba(91, 91, 91, 0.37);
    width: 500px;
}



.navItem{
    border-radius: 0px;
   
}

#navLink {
    border-radius: 0px;
    border: none;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#water-tabs  > a{
    border-radius: 0px;
}