table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

td,
th {
  text-align: center;
  padding: 16px;
}

th {
  background-color: #f9f9f9;
  font-size: 18px;
  color: #375ead;
  border-bottom: 2px solid #0f0f0f;
}

.ColSpan td {
  font-size: 16px;
  color: rgb(152, 5, 5);
  font-weight: bold;
}

.fixTableHead {
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
}
