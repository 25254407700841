
.button {
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 10px;
    padding: 5px 5px;
    border-radius: 4px;
    width: 180px;
}

.button1 {
    background-color: navy;
}

.button1:hover {
    box-shadow: 0px 0px 4px 4px #b8cdf2;
}

.button1:active {
    font-size: 16px;
}