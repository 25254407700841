table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 14px;
  
  }

  th{
    background-color: rgb(231, 231, 231);
    font-size: 18px;
  }


.FirstRow th{
border-top: none;
}

.LastRow td{
  border-bottom: none;
  }

  .dropdown-toggle {
    background-color: #fff;
    color: #000; /* Set the text color */
    border: 1px solid #ced4da; /* Set the border color */
  }