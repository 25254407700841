.process-water-line {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
padding: 80px 0px;
}

.process-water-line .div {
  background-color: #F9F9F9;
  border: 1px none;
  height: 2447px;
  overflow: hidden;
  position: relative;
  width: 85%;
}

.process-water-line .overlap {
  background-color: #ffffff;
  box-shadow: 0px 3.01px 6.78px #365ead21;
  height: 67px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.process-water-line .ci-hamburger-md {
  height: 34px;
  left: 30px;
  position: absolute;
  top: 16px;
  width: 34px;
}

.process-water-line .image {
  height: 36px;
  left: 1107px;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 36px;
}

.process-water-line .text-wrapper {
  color: #43abe0;
  font-family: "Lato", Helvetica;
  font-size: 16.7px;
  font-weight: 600;
  left: 1156px;
  letter-spacing: 0.83px;
  line-height: normal;
  position: absolute;
  top: 22px;
  white-space: nowrap;
}

.process-water-line .text-wrapper-2 {
  color: var(--primary-blue);
  font-family: "Lato", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 87px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.process-water-line .overlap-group {
  height: 2340px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
}

.process-water-line .overlap-group-2 {
  height: 2340px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.process-water-line .line {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 2339px;
  width: 100%;
}

.process-water-line .rectangle {
  background-color: #F9F9F9;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 4px 0px 8px #0000000d;
  height: 75px;
  left: 219px;
  position: absolute;
  top: 0;
  width: 220px;
}

.process-water-line .rectangle-2 {
  background-color: #F9F9F9;
  height: 2276px;
  left: 0;
  position: absolute;
  top: 0px;
  width: 100%;
}

.process-water-line .rectangle-3 {

  border-radius: 2.91px;
  height: 2182px;
  left: 47px;
  position: absolute;
  top: 111px;
  width: 100%;
}

.process-water-line .text-wrapper-3 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 600;
  left: 271px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.process-water-line .rectangle-4 {
  background-color: #f7f7f7;
  border: 2px solid;
  border-color: #1bbe1b;
  border-radius: 2.91px;
  height: 57px;
  left: 83px;
  position: absolute;
  top: 146px;
  width: 357px;
}

.process-water-line .rectangle-5 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #365ead;
  border-radius: 2.91px;
  height: 57px;
  left: 466px;
  position: absolute;
  top: 146px;
  width: 356px;
}

.process-water-line .rectangle-6 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #365ead;
  border-radius: 2.91px;
  height: 57px;
  left: 848px;
  position: absolute;
  top: 146px;
  width: 357px;
}

.process-water-line .text-wrapper-4 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: bold;
  left: 190px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 164px;
  white-space: nowrap;
}

.process-water-line .text-wrapper-5 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 941px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 164px;
  white-space: nowrap;
}

.process-water-line .text-wrapper-6 {
  color: #365ead;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 569px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 164px;
  white-space: nowrap;
}

.process-water-line .vector {
  height: 8px;
  left: 1054px;
  position: absolute;
  top: 2219px;
  width: 4px;
}

.process-water-line .img {
  height: 8px;
  left: 292px;
  position: absolute;
  top: 2219px;
  width: 5px;
}

.process-water-line .vector-2 {
  height: 1px;
  left: 626px;
  position: absolute;
  top: 1284px;
  width: 10px;
}

.process-water-line .vector-3 {
  height: 1px;
  left: 626px;
  position: absolute;
  top: 1209px;
  width: 9px;
}

.process-water-line .vector-4 {
  height: 72px;
  left: 625px;
  position: absolute;
  top: 1028px;
  width: 1px;
}

.process-water-line .text-wrapper-7 {
  color: #231f20;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 934px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 381px;
  width: 82px;
}

.process-water-line .text-wrapper-8 {
  color: #231f20;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 934px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1872px;
  width: 82px;
}

.process-water-line .text {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 214px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 501px;
  width: 1px;
}

.process-water-line .vector-5 {
  height: 1px;
  left: 299px;
  position: absolute;
  top: 2227px;
  width: 753px;
}

.process-water-line .vector-6 {
  height: 356px;
  left: 292px;
  position: absolute;
  top: 1860px;
  width: 1px;
}

.process-water-line .vector-7 {
  height: 6px;
  left: 292px;
  position: absolute;
  top: 1852px;
  width: 5px;
}

.process-water-line .vector-8 {
  height: 1px;
  left: 299px;
  position: absolute;
  top: 1852px;
  width: 752px;
}

.process-water-line .vector-9 {
  height: 6px;
  left: 1052px;
  position: absolute;
  top: 1852px;
  width: 6px;
}

.process-water-line .vector-10 {
  height: 357px;
  left: 1058px;
  position: absolute;
  top: 1860px;
  width: 1px;
}

.process-water-line .vector-11 {
  height: 6px;
  left: 1054px;
  position: absolute;
  top: 1806px;
  width: 4px;
}

.process-water-line .vector-12 {
  height: 1px;
  left: 210px;
  position: absolute;
  top: 1812px;
  width: 842px;
}

.process-water-line .vector-13 {
  height: 6px;
  left: 200px;
  position: absolute;
  top: 1806px;
  width: 8px;
}

.process-water-line .vector-14 {
  height: 5px;
  left: 200px;
  position: absolute;
  top: 334px;
  width: 6px;
}

.process-water-line .vector-15 {
  height: 1px;
  left: 208px;
  position: absolute;
  top: 334px;
  width: 838px;
}

.process-water-line .vector-16 {
  height: 5px;
  left: 1053px;
  position: absolute;
  top: 334px;
  width: 5px;
}

.process-water-line .vector-17 {
  height: 1462px;
  left: 1058px;
  position: absolute;
  top: 342px;
  width: 1px;
}

.process-water-line .vector-18 {
  height: 106px;
  left: 414px;
  position: absolute;
  top: 508px;
  width: 177px;
}

.process-water-line .vector-19 {
  height: 20px;
  left: 666px;
  position: absolute;
  top: 679px;
  width: 87px;
}

.process-water-line .vector-20 {
  height: 19px;
  left: 666px;
  position: absolute;
  top: 760px;
  width: 87px;
}

.process-water-line .vector-21 {
  height: 134px;
  left: 398px;
  position: absolute;
  top: 818px;
  width: 328px;
}

.process-water-line .vector-22 {
  height: 194px;
  left: 862px;
  position: absolute;
  top: 799px;
  width: 152px;
}

.process-water-line .vector-23 {
  height: 77px;
  left: 913px;
  position: absolute;
  top: 1024px;
  width: 83px;
}

.process-water-line .vector-24 {
  height: 120px;
  left: 369px;
  position: absolute;
  top: 1020px;
  width: 430px;
}

.process-water-line .text-wrapper-9 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 450px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1065px;
  width: 98px;
}

.process-water-line .vector-25 {
  height: 76px;
  left: 635px;
  position: absolute;
  top: 1209px;
  width: 148px;
}

.process-water-line .vector-26 {
  height: 96px;
  left: 475px;
  position: absolute;
  top: 1201px;
  width: 162px;
}

.process-water-line .vector-27 {
  height: 18px;
  left: 644px;
  position: absolute;
  top: 559px;
  width: 78px;
}

.process-water-line .vector-28 {
  height: 17px;
  left: 337px;
  position: absolute;
  top: 909px;
  width: 61px;
}

.process-water-line .vector-29 {
  height: 18px;
  left: 337px;
  position: absolute;
  top: 889px;
  width: 61px;
}

.process-water-line .vector-30 {
  height: 13px;
  left: 909px;
  position: absolute;
  top: 1139px;
  width: 48px;
}

.process-water-line .vector-31 {
  height: 15px;
  left: 909px;
  position: absolute;
  top: 1158px;
  width: 48px;
}

.process-water-line .vector-32 {
  height: 13px;
  left: 919px;
  position: absolute;
  top: 1528px;
  width: 60px;
}

.process-water-line .vector-33 {
  height: 13px;
  left: 961px;
  position: absolute;
  top: 1619px;
  width: 60px;
}

.process-water-line .vector-34 {
  height: 14px;
  left: 918px;
  position: absolute;
  top: 1488px;
  width: 66px;
}

.process-water-line .vector-35 {
  height: 81px;
  left: 655px;
  position: absolute;
  top: 1676px;
  width: 62px;
}

.process-water-line .vector-36 {
  height: 68px;
  left: 370px;
  position: absolute;
  top: 1943px;
  width: 87px;
}

.process-water-line .vector-37 {
  height: 68px;
  left: 478px;
  position: absolute;
  top: 1943px;
  width: 50px;
}

.process-water-line .vector-38 {
  height: 68px;
  left: 572px;
  position: absolute;
  top: 1943px;
  width: 52px;
}

.process-water-line .vector-39 {
  height: 68px;
  left: 650px;
  position: absolute;
  top: 1943px;
  width: 55px;
}

.process-water-line .vector-40 {
  height: 68px;
  left: 732px;
  position: absolute;
  top: 1943px;
  width: 84px;
}

.process-water-line .OFFICE-BUILDING {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 657px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1967px;
  width: 42px;
}

.process-water-line .vector-41 {
  height: 16px;
  left: 630px;
  position: absolute;
  top: 2043px;
  width: 54px;
}

.process-water-line .vector-42 {
  height: 16px;
  left: 630px;
  position: absolute;
  top: 2072px;
  width: 54px;
}

.process-water-line .vector-43 {
  height: 13px;
  left: 870px;
  position: absolute;
  top: 2095px;
  width: 64px;
}

.process-water-line .vector-44 {
  height: 16px;
  left: 870px;
  position: absolute;
  top: 2121px;
  width: 64px;
}

.process-water-line .vector-45 {
  height: 16px;
  left: 870px;
  position: absolute;
  top: 2154px;
  width: 64px;
}

.process-water-line .vector-46 {
  height: 15px;
  left: 870px;
  position: absolute;
  top: 2183px;
  width: 64px;
}

.process-water-line .vector-47 {
  height: 37px;
  left: 629px;
  position: absolute;
  top: 1494px;
  width: 174px;
}

.process-water-line .vector-48 {
  height: 16px;
  left: 902px;
  position: absolute;
  top: 1357px;
  width: 71px;
}

.process-water-line .UNDERGROUND-TANK {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 376px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1968px;
  width: 73px;
}

.process-water-line .PUMP-FOR-LIFT {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 489px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1963px;
  width: 28px;
}

.process-water-line .TERRACE-TANK {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 580px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1967px;
  width: 36px;
}

.process-water-line .text-wrapper-10 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 746px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1968px;
  white-space: nowrap;
  width: 55px;
}

.process-water-line .text-wrapper-11 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 635px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2045px;
  white-space: nowrap;
  width: 43px;
}

.process-water-line .text-wrapper-12 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 634px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2074px;
  white-space: nowrap;
  width: 50px;
}

.process-water-line .text-wrapper-13 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 878px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2096px;
  width: 47px;
}

.process-water-line .text-wrapper-14 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 886px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2124px;
  width: 32px;
}

.process-water-line .text-wrapper-15 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 875px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2157px;
  white-space: nowrap;
  width: 54px;
}

.process-water-line .text-wrapper-16 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 887px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2185px;
  width: 30px;
}

.process-water-line .text-wrapper-17 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 497px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1233px;
  width: 118px;
}

.process-water-line .text-wrapper-18 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 668px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1056px;
  width: 101px;
}

.process-water-line .text-wrapper-19 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 512px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 873px;
  white-space: nowrap;
  width: 98px;
}

.process-water-line .text-wrapper-20 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 923px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 883px;
  width: 29px;
}

.process-water-line .text-wrapper-21 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 481px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 558px;
  width: 45px;
}

.process-water-line .line-2 {
  height: 72px;
  left: 624px;
  position: absolute;
  top: 1028px;
  width: 1px;
}

.process-water-line .vector-49 {
  height: 14px;
  left: 700px;
  position: absolute;
  top: 1173px;
  width: 83px;
}

.process-water-line .text-wrapper-22 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 684px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 683px;
  white-space: nowrap;
  width: 53px;
}

.process-water-line .vector-50 {
  height: 48px;
  left: 666px;
  position: absolute;
  top: 706px;
  width: 87px;
}

.process-water-line .text-wrapper-23 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 690px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 724px;
  width: 37px;
}

.process-water-line .text-wrapper-24 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 665px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 561px;
  width: 44px;
}

.process-water-line .text-wrapper-25 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 678px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 763px;
  white-space: nowrap;
  width: 62px;
}

.process-water-line .text-wrapper-26 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 345px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 911px;
  white-space: nowrap;
  width: 44px;
}

.process-water-line .text-wrapper-27 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 344px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 892px;
  white-space: nowrap;
  width: 46px;
}

.process-water-line .SUPPORT-SERVICE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 923px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1044px;
  width: 48px;
}

.process-water-line .text-wrapper-28 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 918px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1140px;
  width: 30px;
}

.process-water-line .text-wrapper-29 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 918px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1160px;
  white-space: nowrap;
  width: 29px;
}

.process-water-line .text-wrapper-30 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 708px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1175px;
  width: 80px;
}

.process-water-line .text-wrapper-31 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 663px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1239px;
  white-space: nowrap;
  width: 91px;
}

.process-water-line .text-wrapper-32 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 909px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1359px;
  white-space: nowrap;
  width: 63px;
}

.process-water-line .text-wrapper-33 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 693px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1505px;
  width: 46px;
}

.process-water-line .text-wrapper-34 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 931px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1489px;
  white-space: nowrap;
  width: 51px;
}

.process-water-line .text-wrapper-35 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 928px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1529px;
  width: 68px;
}

.process-water-line .text-wrapper-36 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 971px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1620px;
  width: 39px;
}

.process-water-line .text-wrapper-37 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 662px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1710px;
  width: 65px;
}

.process-water-line .line-3 {
  height: 15px;
  left: 300px;
  object-fit: cover;
  position: absolute;
  top: 445px;
  width: 1px;
}

.process-water-line .vector-51 {
  height: 89px;
  left: 316px;
  position: absolute;
  top: 2092px;
  width: 43px;
}

.process-water-line .TANK-EACH {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 319px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2096px;
  width: 34px;
}

.process-water-line .vector-52 {
  height: 25px;
  left: 326px;
  position: absolute;
  top: 2118px;
  width: 25px;
}

.process-water-line .vector-53 {
  height: 25px;
  left: 326px;
  position: absolute;
  top: 2150px;
  width: 25px;
}

.process-water-line .vector-54 {
  height: 62px;
  left: 866px;
  position: absolute;
  top: 498px;
  width: 89px;
}

.process-water-line .vector-55 {
  height: 60px;
  left: 866px;
  position: absolute;
  top: 570px;
  width: 89px;
}

.process-water-line .text-wrapper-38 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 881px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 521px;
  white-space: nowrap;
}

.process-water-line .PUMP-HOUSE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 888px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 581px;
}

.process-water-line .vector-56 {
  height: 22px;
  left: 666px;
  position: absolute;
  top: 785px;
  width: 61px;
}

.process-water-line .paint-compressor-connector{
  height: 20px;
  left: 691px;
  object-fit: cover;
  position: absolute;
  top: 807px;
  width: 1px;
}

.process-water-line .COMPRESSOR-ROOM-n {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 673px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 788px;
}

.process-water-line .vector-57 {
  height: 14px;
  left: 413px;
  position: absolute;
  top: 1151px;
  width: 21px;
}

.process-water-line .vector-58 {
  height: 14px;
  left: 441px;
  position: absolute;
  top: 1151px;
  width: 21px;
}

.process-water-line .text-wrapper-39 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 417px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1153px;
  white-space: nowrap;
}

.process-water-line .text-wrapper-40 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 445px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1153px;
  white-space: nowrap;
}

.process-water-line .vector-59 {
  height: 14px;
  left: 402px;
  position: absolute;
  top: 1323px;
  width: 71px;
}

.process-water-line .vector-60 {
  height: 14px;
  left: 549px;
  position: absolute;
  top: 1323px;
  width: 71px;
}

.process-water-line .text-wrapper-41 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 405px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1325px;
  white-space: nowrap;
}

.process-water-line .text-wrapper-42 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 558px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1325px;
  white-space: nowrap;
}

.process-water-line .vector-61 {
  height: 67px;
  left: 425px;
  position: absolute;
  top: 1506px;
  width: 186px;
}

.process-water-line .vector-62 {
  height: 16px;
  left: 538px;
  position: absolute;
  top: 1470px;
  width: 65px;
}

.process-water-line .vector-63 {
  height: 67px;
  left: 610px;
  position: absolute;
  top: 1572px;
  width: 186px;
}

.process-water-line .text-wrapper-43 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 544px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1473px;
  white-space: nowrap;
}

.process-water-line .p {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 449px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1534px;
  white-space: nowrap;
}

.process-water-line .text-wrapper-44 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 634px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1598px;
  white-space: nowrap;
}

.process-water-line .vector-64 {
  height: 81px;
  left: 793px;
  position: absolute;
  top: 1676px;
  width: 62px;
}

.process-water-line .SOLAR-CAR-PARKING {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 802px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1700px;
}

.process-water-line .vector-65 {
  height: 55px;
  left: 886px;
  position: absolute;
  top: 1930px;
  width: 91px;
}

.process-water-line .element-LITRE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 899px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1946px;
}

.process-water-line .element-LITRE-2 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 944px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1946px;
}

.process-water-line .line-4 {
  height: 54px;
  left: 930px;
  position: absolute;
  top: 1930px;
  width: 1px;
}


.process-water-line .vector-66 {
  height: 16px;
  left: 281px;
  position: absolute;
  top: 445px;
  width: 39px;
}

.process-water-line .line-5 {
  height: 35px;
  left: 298px;
  object-fit: cover;
  position: absolute;
  top: 489px;
  width: 1px;
}

.process-water-line .t1-to-line-5 {
  height: 29px;
  left: 290px;
  object-fit: cover;
  position: absolute;
  top: 455px;
  width: 1px;
}

.process-water-line .t2-to-line-5 {
  height: 29px;
  left: 307px;
  object-fit: cover;
  position: absolute;
  top: 455px;
  width: 1px;
}

.process-water-line .text-wrapper-45 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 306px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 445px;
  width: 9px;
}

.process-water-line .text-wrapper-46 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 6px;
  font-weight: 400;
  left: 287px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 445px;
  width: 8px;
}

.process-water-line .vector-67 {
  height: 1463px;
  left: 200px;
  position: absolute;
  top: 341px;
  width: 1px;
}

.process-water-line .line-6 {
  height: 81px;
  left: 212px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 1px;
}

.process-water-line .line-7 {
  height: 127px;
  left: 371px;
  object-fit: cover;
  position: absolute;
  top: 573px;
  width: 1px;
}

.process-water-line .line-8 {
  height: 88px;
  left: 684px;
  object-fit: cover;
  position: absolute;
  top: 939px;
  width: 1px;
}

.process-water-line .line-9 {
  height: 18px;
  left: 620px;
  object-fit: cover;
  position: absolute;
  top: 808px;
  width: 1px;
}

.process-water-line .line-10 {
  height: 18px;
  left: 519px;
  object-fit: cover;
  position: absolute;
  top: 808px;
  width: 1px;
}

.process-water-line .line-11 {
  height: 178px;
  left: 910px;
  object-fit: cover;
  position: absolute;
  top: 629px;
  width: 1px;
}

.process-water-line .line-12 {
  height: 1px;
  left: 629px;
  object-fit: cover;
  position: absolute;
  top: 670px;
  width: 281px;
}

.process-water-line .line-13 {
  height: 1px;
  left: 212px;
  object-fit: cover;
  position: absolute;
  top: 553px;
  width: 15px;
}

.process-water-line .line-14 {
  height: 1px;
  left: 364px;
  object-fit: cover;
  position: absolute;
  top: 573px;
  width: 8px;
}

.process-water-line .line-15 {
  height: 1px;
  left: 371px;
  object-fit: cover;
  position: absolute;
  top: 699px;
  width: 9px;
}

.process-water-line .line-16 {
  height: 33px;
  left: 504px;
  object-fit: cover;
  position: absolute;
  top: 601px;
  width: 1px;
}

.process-water-line .line-17 {
  height: 1px;
  left: 212px;
  object-fit: cover;
  position: absolute;
  top: 633px;
  width: 292px;
}

.process-water-line .line-18 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 808px;
  width: 348px;
}

.process-water-line .line-19 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 843px;
  width: 136px;
}

.process-water-line .line-20 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 1062px;
  width: 107px;
}

.process-water-line .line-21 {
  height: 1px;
  left: 788px;
  object-fit: cover;
  position: absolute;
  top: 1062px;
  width: 125px;
}

.process-water-line .line-22 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 726px;
  width: 107px;
}

.process-water-line .line-23 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 754px;
  width: 107px;
}

.process-water-line .line-24 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 873px;
  width: 136px;
}

.process-water-line .line-25 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 1092px;
  width: 107px;
}

.process-water-line .line-26 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 1329px;
  width: 130px;
}

.process-water-line .line-27 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 1364px;
  width: 108px;
}

.process-water-line .line-28 {
  height: 1px;
  left: 289px;
  object-fit: cover;
  position: absolute;
  top: 1389px;
  width: 90px;
}

.process-water-line .line-29 {
  height: 1px;
  left: 289px;
  object-fit: cover;
  position: absolute;
  top: 1406px;
  width: 90px;
}

.process-water-line .line-30 {
  height: 1px;
  left: 289px;
  object-fit: cover;
  position: absolute;
  top: 1434px;
  width: 90px;
}

.process-water-line .line-31 {
  height: 1px;
  left: 269px;
  object-fit: cover;
  position: absolute;
  top: 1453px;
  width: 7px;
}

.process-water-line .line-32 {
  height: 11px;
  left: 583px;
  object-fit: cover;
  position: absolute;
  top: 1336px;
  width: 1px;
}

.process-water-line .line-33 {
  height: 70px;
  left: 289px;
  object-fit: cover;
  position: absolute;
  top: 1365px;
  width: 1px;
}

.process-water-line .line-34 {
  height: 53px;
  left: 661px;
  object-fit: cover;
  position: absolute;
  top: 1441px;
  width: 1px;
}

.process-water-line .line-35 {
  height: 53px;
  left: 479px;
  object-fit: cover;
  position: absolute;
  top: 1453px;
  width: 1px;
}

.process-water-line .line-36 {
  height: 42px;
  left: 661px;
  object-fit: cover;
  position: absolute;
  top: 1530px;
  width: 1px;
}

.process-water-line .line-37 {
  height: 185px;
  left: 880px;
  object-fit: cover;
  position: absolute;
  top: 1551px;
  width: 1px;
}

.process-water-line .line-38 {
  height: 1px;
  left: 479px;
  object-fit: cover;
  position: absolute;
  top: 1477px;
  width: 59px;
}

.process-water-line .line-39 {
  height: 1px;
  left: 854px;
  object-fit: cover;
  position: absolute;
  top: 1735px;
  width: 26px;
}

.process-water-line .line-40 {
  height: 1px;
  left: 661px;
  object-fit: cover;
  position: absolute;
  top: 1551px;
  width: 219px;
}

.process-water-line .line-41 {
  height: 1px;
  left: 146px;
  object-fit: cover;
  position: absolute;
  top: 1112px;
  width: 126px;
}

.process-water-line .line-42 {
  height: 1px;
  left: 146px;
  object-fit: cover;
  position: absolute;
  top: 1156px;
  width: 36px;
}

.process-water-line .line-43 {
  height: 44px;
  left: 181px;
  object-fit: cover;
  position: absolute;
  top: 1113px;
  width: 1px;
}

.process-water-line .line-44 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 740px;
  width: 107px;
}

.process-water-line .line-45 {
  height: 1px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 768px;
  width: 107px;
}

.process-water-line .vector-68 {
  height: 97px;
  left: 227px;
  position: absolute;
  top: 524px;
  width: 138px;
}

.process-water-line .PUMP-HOUSE-2 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 258px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 555px;
  width: 74px;
}

.process-water-line .vector-69 {
  height: 150px;
  left: 369px;
  position: absolute;
  top: 648px;
  width: 271px;
}

.process-water-line .text-wrapper-47 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 481px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 710px;
  width: 45px;
}

.process-water-line .line-46 {
  height: 820px;
  left: 272px;
  object-fit: cover;
  position: absolute;
  top: 634px;
  width: 1px;
}

.process-water-line .vector-70 {
  height: 130px;
  left: 368px;
  position: absolute;
  top: 1336px;
  width: 444px;
}

.process-water-line .text-wrapper-48 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 479px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1398px;
  width: 113px;
}

.process-water-line .polygon {
  height: 5px;
  left: 294px;
  position: absolute;
  top: 512px;
  width: 9px;
}

.process-water-line .polygon-2 {
  height: 10px;
  left: 364px;
  position: absolute;
  top: 569px;
  width: 5px;
}

.process-water-line .polygon-3 {
  height: 5px;
  left: 367px;
  position: absolute;
  top: 623px;
  width: 10px;
}

.process-water-line .polygon-4 {
  height: 10px;
  left: 289px;
  position: absolute;
  top: 629px;
  width: 5px;
}

.process-water-line .polygon-5 {
  height: 10px;
  left: 289px;
  position: absolute;
  top: 722px;
  width: 5px;
}

.process-water-line .polygon-6 {
  height: 10px;
  left: 342px;
  position: absolute;
  top: 736px;
  width: 5px;
}

.process-water-line .polygon-7 {
  height: 10px;
  left: 342px;
  position: absolute;
  top: 750px;
  width: 5px;
}

.process-water-line .polygon-8 {
  height: 10px;
  left: 342px;
  position: absolute;
  top: 764px;
  width: 5px;
}

.process-water-line .polygon-9 {
  height: 10px;
  left: 342px;
  position: absolute;
  top: 804px;
  width: 5px;
}

.process-water-line .polygon-10 {
  height: 5px;
  left: 515px;
  position: absolute;
  top: 816px;
  width: 10px;
}

.process-water-line .polygon-11 {
  height: 5px;
  left: 616px;
  position: absolute;
  top: 816px;
  width: 10px;
}

.process-water-line .polygon-12 {
  height: 10px;
  left: 848px;
  position: absolute;
  top: 666px;
  width: 5px;
}

.process-water-line .polygon-13 {
  height: 5px;
  left: 906px;
  position: absolute;
  top: 646px;
  width: 10px;
}

.process-water-line .polygon-14 {
  height: 10px;
  left: 342px;
  position: absolute;
  top: 839px;
  width: 5px;
}

.process-water-line .polygon-15 {
  height: 10px;
  left: 342px;
  position: absolute;
  top: 869px;
  width: 5px;
}

.process-water-line .polygon-16 {
  height: 10px;
  left: 342px;
  position: absolute;
  top: 1058px;
  width: 5px;
}

.process-water-line .polygon-17 {
  height: 10px;
  left: 342px;
  position: absolute;
  top: 1088px;
  width: 5px;
}

.process-water-line .polygon-18 {
  height: 10px;
  left: 312px;
  position: absolute;
  top: 1325px;
  width: 5px;
}

.process-water-line .polygon-19 {
  height: 10px;
  left: 312px;
  position: absolute;
  top: 1360px;
  width: 5px;
}

.process-water-line .polygon-20 {
  height: 10px;
  left: 312px;
  position: absolute;
  top: 1385px;
  width: 5px;
}

.process-water-line .polygon-21 {
  height: 10px;
  left: 312px;
  position: absolute;
  top: 1402px;
  width: 5px;
}

.process-water-line .polygon-22 {
  height: 10px;
  left: 734px;
  position: absolute;
  top: 1547px;
  width: 5px;
}

.process-water-line .polygon-23 {
  height: 10px;
  left: 312px;
  position: absolute;
  top: 1430px;
  width: 5px;
}

.process-water-line .polygon-24 {
  height: 10px;
  left: 143px;
  position: absolute;
  top: 1108px;
  width: 5px;
}

.process-water-line .polygon-25 {
  height: 10px;
  left: 143px;
  position: absolute;
  top: 1152px;
  width: 5px;
}

.process-water-line .vector-71 {
  height: 30px;
  left: 290px;
  position: absolute;
  top: 412px;
  width: 18px;
}

.process-water-line .FROM-PUMP-HOUSE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 10px;
  font-weight: 400;
  left: 265px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 360px;
  width: 75px;
}

.process-water-line .line-47 {
  height: 21px;
  left: 300px;
  object-fit: cover;
  position: absolute;
  top: 393px;
  width: 1px;
}

.process-water-line .m1-group {
  height: 15px;
  left: 301px;
  position: absolute;
  top: 420px;
  width: 15px;
}

.process-water-line .m2-group {
  height: 15px;
  left: 284px;
  position: absolute;
  top: 420px;
  width: 15px;
}
.process-water-line .m3-group {
  height: 15px;
  left: 282px;
  position: absolute;
  top: 460px;
  width: 15px;
}
.process-water-line .m5-group {
  height: 15px;
  left: 290px;
  position: absolute;
  top: 493px;
  width: 15px;
}
.process-water-line .m11-group {
  height: 15px;
  left: 452px;
  position: absolute;
  top: 626px;
  width: 15px;
}
.process-water-line .m12-group {
  height: 15px;
  left: 350px;
  position: absolute;
  top: 720px;
  width: 15px;
}
.process-water-line .m13-group {
  height: 15px;
  left: 372px;
  position: absolute;
  top: 741px;
  width: 15px;
}
.process-water-line .m15-group {
  height: 15px;
  left: 350px;
  position: absolute;
  top: 760px;
  width: 15px;
}

.process-water-line .m17-group {
  height: 15px;
  left: 450px;
  position: absolute;
  top: 800px;
  width: 15px;
}

.process-water-line .m28-group {
  height: 15px;
  left: 685px;
  position: absolute;
  top: 810px;
  width: 15px;
}
.process-water-line .m18-group {
  height: 15px;
  left: 395px;
  position: absolute;
  top: 865px;
  width: 15px;
}
.process-water-line .m22-group {
  height: 15px;
  left: 350px;
  position: absolute;
  top: 1055px;
  width: 15px;
}

.process-water-line .m23-group {
  height: 15px;
  left: 350px;
  position: absolute;
  top: 1085px;
  width: 15px;
}

.process-water-line .m29-group {
  height: 15px;
  left: 343px;
  position: absolute;
  top: 1355px;
  width: 15px;
}

.process-water-line .m34-group {
  height: 15px;
  left: 380px;
  position: absolute;
  top: 1322px;
  width: 15px;
}

.process-water-line .m40-group {
  height: 15px;
  left: 472px;
  position: absolute;
  top: 1458px;
  width: 15px;
}

.process-water-line .m41-group {
  height: 15px;
  left: 654px;
  position: absolute;
  top: 1458px;
  width: 15px;
}

.process-water-line .group-3 {
  height: 15px;
  left: 677px;
  position: absolute;
  top: 975px;
  width: 15px;
}

.process-water-line .group-4 {
  height: 15px;
  left: 860px;
  position: absolute;
  top: 1728px;
  width: 15px;
}



.process-water-line .TO-j-BLOCK {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 130px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1126px;
}

.process-water-line .ENGINE-MAIN-LINE {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 326px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1337px;
}

.process-water-line .text-wrapper-49 {
  color: #000000;
  font-family: "Lato", Helvetica;
  font-size: 8px;
  font-weight: 400;
  left: 363px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 792px;
}

.process-water-line .text-wrapper-50 {
  color: #5a5a5a;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 493px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.process-water-line .text-wrapper-51 {
  color: #5a5a5a;
  font-family: "Lato", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.process-water-line .text-wrapper-52 {
  color: var(--grey-01);
  font-family: "Lato", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 507px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2417px;
  width: 266px;
}

.process-water-line .vector-72 {
  height: 100px;
  left: -22409px;
  position: absolute;
  top: -9331px;
  width: 100px;
}
